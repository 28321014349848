<template>
	<section class="app-hero">
		<section class="app-hero__top">
			<div class="app-hero__utility">
				<div class="app-hero__title">{{ $route.meta.title }}</div>
				<div class="app-hero__description">{{ $route.meta.description }}</div>
				<!-- TEMPORARILY DISABLED -->
				<!--
				<div class="app-hero__form">
					<div class="app-hero__form-input">
						<input @keyup="getSearchResults" v-model="searchTerm" />
						<button>
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17">
								<defs>
								<clipPath id="clip-path">
								<rect id="Rectangle_98" data-name="Rectangle 98" width="17" height="17" transform="translate(0)" fill="none" stroke="#707070" stroke-width="2"/>
								</clipPath>
								</defs>
								<g id="Icon_Search" data-name="Icon Search" transform="translate(0)">
								<g id="Group_121" data-name="Group 121" clip-path="url(#clip-path)">
								<circle id="Ellipse_14" data-name="Ellipse 14" cx="5.974" cy="5.974" r="5.974" transform="translate(1.764 1.763)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
								<line id="Line_30" data-name="Line 30" x1="3.274" y1="3.274" transform="translate(11.963 11.962)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
								</g>
								</g>
							</svg>
						</button>
					</div>
				</div>
				<div class="app-hero__instructions">Search by team, client, or employee name.</div>
				<div class="app-hero__search-results">
					<p><b>Term:</b>&nbsp; {{ searchTerm }}</p>
					<div v-if="searchResults.length > 0">
						<b>Search Results:</b><br/>
						<ul>
							<li v-for="(item, index) in searchResults" :key="index">
								<b>{{ item.type }}:</b>
								{{ item.label }}
							</li>
						</ul>
					</div>
				</div>
				-->
			</div>
		</section>
		<section class="app-hero__bottom"></section>
	</section>
</template>

<script>
// import { store } from '@/store';

export default {
	data() { return {
		pageTitle: '',
		searchTerm: '',
		searchTermPrevious: null,
		searchResults: [],
		searchResultsSelection: null,
		// store,
	}},
	// created() {
	// 	store.init();
	// },
	// methods: {
	// 	getSearchResults(event) {
	// 		// console.log(event);
	// 		if(this.hasSearchTermChanged()) {
	// 			console.log('search term changing ...');
	// 			this.searchResults = [];
	// 			if(this.searchTerm.length > 0) {
	// 				// TODO: Query for members/employees
	// 				for(let i in this.store.agencyData.employees) {
	// 					let item = this.store.agencyData.employees[i];
	// 					if(item.name.toLowerCase().indexOf(this.searchTerm) >= 0) {
	// 						this.searchResults.push({
	// 							type: 'Member',
	// 							label: item.name,
	// 						});
	// 					}
	// 				}
	// 				// TODO: Query for teams
	// 				// TODO: Query for clients
	// 				// TODO: Query for interests/tags
	// 			}
	// 		} else {
	// 			console.log('search term NOT changing ...');
	// 			console.log(event);
	// 			if(((event.key == 'ArrowUp') || (event.key == 'ArrowDown')) && (this.searchResults.length > 0)) {

	// 			}
	// 		}
	// 		this.syncSearchTermHistory();
	// 	},
	// 	hasSearchTermChanged() {
	// 		return this.searchTerm !== this.searchTermPrevious;
	// 	},
	// 	syncSearchTermHistory() {
	// 		this.searchTermPrevious = this.searchTerm;
	// 	}
	// }
}
</script>

<style lang="scss">
.app-hero {
	display: flex;
	flex-direction: column;
	height: 460px;
	padding-left: 15px;
	padding-right: 15px;
	background: url('../assets/hero-gradient-img.png') center center/cover no-repeat;
	&__top {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		text-align: center;
	}
	&__utility {
		width: 100%;
		max-width: 500px;
	}
	&__title {
		margin-bottom: 10px;
		color: white;
		font: 700 30px/1 'Hind', sans-serif;
		@media screen and (max-width: 449px) {
			font-size: 25px;
		}
	}
	&__description {
		color: white;
	}
	&__form {
		&-input {
			position: relative;
		}
		input {
			display: block;
			height: 47px;
			width: 100%;
			padding: 0 60px 0 15px;
			background: white;
			border: none;
			border-radius: 5px;
			box-shadow: 0px 3px 3px rgba(black, 0.08);
			color: inherit;
			font-size: 16px;
			outline: none;
			text-overflow: ellipsis;
		}
		button {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 47px;
			background: linear-gradient(109deg, #2EF6D2 -10%, #1668CA 125%);
			border: none;
			border-radius: 0 5px 5px 0;
			cursor: pointer;
			outline: none;
			svg {
				display: block;
				height: 17px;
				width: 17px;
			}
		}
	}
	&__instructions {
		margin-top: 8px;
		color: white;
		font-size: 13px;
		line-height: 1;
		@media screen and (max-width: 449px) {
			font-size: 13px;
		}
	}

	// START TEMPORARILY STYLE:  ADJUST HERO WHEN SEARCH IS DISABLED
	height: 200px;
	@media screen and (min-width: 450px) {
		&__title {
			font-size: 35px;
		}
	}
	// END TEMPORARILY STYLE



	&__search-results { // TEMP
		background: red;
		color: white;
		text-align: left;
	}
}
</style>