<template>
	<AppHeader />
	<section class="app-body">
		<AppHero />
		<slot/>
	</section>
	<AppFooter />
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppHero from "../components/AppHero.vue";
import AppFooter from "../components/AppFooter.vue";
export default {
	components: {
		AppHeader,
		AppHero,
		AppFooter,
	}
}
</script>

<style lang="scss">
.app-body {
	@media screen and (max-width: 959px) {
		margin-top: 58px;
	}
	@media screen and (min-width: 960px) {
		margin-left: 220px;
	}
}
</style>