<template>
	<component :is="layout">
		<router-view/>
	</component>
</template>

<script>
import LayoutUser from './layouts/LayoutUser.vue';
import LayoutGuest from './layouts/LayoutGuest.vue';

const layoutDefault = 'LayoutUser';

export default {
	components: {
		LayoutUser,
		LayoutGuest,
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || layoutDefault);
		}
	}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@700&family=Roboto:wght@400;600&display=swap');



// ===== GENERAL STYLES
// ================================================================================

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}
html {
	font-size: 16px;
}
body {
	margin: 0;
	padding: 0;
	background: white;
	color: #2B2F36;
	font: normal 15px/1.4 'Roboto', sans-serif;
}



// ===== TYPOGRAPHY - HEADINGS
// ================================================================================

h1, h2, h3, h4, h5, h6 {
	margin: 20px 0;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-transform: inherit;
}
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 20px 0;
	color: inherit;
}



// ===== TYPOGRAPHY - PARAGRAPHS
// ================================================================================

p {
	margin: 20px 0;
}



// ===== TYPOGRAPHY - ANCHORS / LINKS
// ================================================================================

a {
	color: inherit;
	cursor: pointer;
	text-decoration: underline;
	&:focus,
	&:hover {
		text-decoration: none;
	}
	&[href*="tel:"] {
		color: inherit;
		cursor: default;
		text-decoration: none !important;
	}
}



// ===== TYPOGRAPHY - FONT WEIGHTS / STYLES
// ================================================================================

b, strong {
	font-weight: bold;
}
i, em {
	font-style: italic;
}



// ===== TYPOGRAPHY - ADDRESS
// ================================================================================

address {
	font-style: normal;
}



// ===== TYPOGRAPHY - PREFORMATTED TEXT
// ================================================================================

pre {
	font-size: inherit;
	white-space: pre-wrap;
}



// ===== UNORDERED LISTS
// ================================================================================

// Normalized <ul>'s
.ul,
.ul ul {
	margin: 20px 0;
	padding: 0;
	list-style: none;
	ul {
		margin: 0;
	}
	li {
		position: relative;
		padding-left: 35px;
		&:before {
			position: absolute;
			left: 15px;
			display: block;
			content: "\2022";
		}
	}
}

// Style:  None / Unformatted
.ul--none,
.ul--none ul {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		padding-left: 0;
		&:before {
			display: none;
		}
	}
}



// ===== IMAGES
// ================================================================================

img {
	display: block;
	height: auto;
	width: auto;
	max-width: 100%;
}
figure {
	margin: 0 0 20px 0;
}



// ===== PRIMARY CONTENT PERIMETER
// ================================================================================

.perimeter {
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 20px;
	&--small {
		max-width: 800px;
	}
	&--medium {
		max-width: 960px;
	}
	&--large {
		max-width: 1600px;
	}
}



// ===== PRIMARY CONTENT PERIMETER
// ================================================================================

.scrollbar--custom {
	scrollbar-color: rgba(#0E1B50, 0.3) rgba(black, 0);
	&::-webkit-scrollbar {
		width: 12px;
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		// background: rgba(#0E1B50, 0.3);
		background: transparent;
		border: solid transparent 3px; // spacing from edge of container
		border-radius: 8px;
		box-shadow: 4px 0 0 4px rgba(#0E1B50, 0.3) inset;
	}
	// &::-webkit-scrollbar-track {
		// background: #D5D8DB;
		// background: rgba(black, 0.2);
		// border-radius: 3px;
	// }
}

// ===== BUTTONS
// ================================================================================

.btn {
	appearance: none;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	font: 700 13px/1 'Hind', sans-serif;
	padding: 12px 30px 10px;
	min-width: 100px;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 200ms ease;
}

.btn-primary {
	background: transparent linear-gradient(102deg, #0E1B50 0%, #0E1B50 100%) center center no-repeat;
	color: white;

	&:hover,
	&:focus {
		background-image: linear-gradient(102deg, #2EF6D2 0%, #1668CA 49%, #223377 100%);
	}
}

</style>
