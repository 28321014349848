import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
		meta: {
			title: 'Welcome to TriMark',
			requiresAuth: true,
		},
	},
	{
		path: '/resources',
		name: 'resources',
		component: () => import(/* webpackChunkName: "resources" */ '../views/Page.vue'),
		props: {
			postId: 732,
		},
		meta: {
			title: 'HR Resources',
			description: 'Contact Molly Corrigan, People Operations Manager, for other questions or concerns.',
			requiresAuth: true,
		}
	},
	{
		path: '/trimarku-trainings',
		name: 'trimarku-trainings',
		component: () => import(/* webpackChunkName: "resources" */ '../views/Page.vue'),
		props: {
			postId: 734,
		},
		meta: {
			title: 'TriMark U',
			description: 'TriMark U Trainings',
			requiresAuth: true,
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "resources" */ '../views/Login.vue'),
		meta: {
			title: 'Login',
			description: ''
		}
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	// Assume all routes are protected
	if (to.meta.requiresAuth) {
		const token = localStorage.getItem('TriMarkFlowAuthToken');
		if (token) {
			// Document title
			window.document.title = to.meta && to.meta.title ? `${to.meta.title} | TriMark Digital - Flow` : 'Home | TriMark Digital - Flow';
			// User is authenticated, proceed to the route
			next();
		} else {
			// User is not authenticated, redirect to login
			next('/login');
		}
	} else {
		// Document title
		window.document.title = to.meta && to.meta.title ? `${to.meta.title} | TriMark Digital - Flow` : 'Home | TriMark Digital - Flow';
		// Non-protected route, allow access
		next();
	}
});

export default router;
